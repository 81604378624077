
:where(.css-dev-only-do-not-override-12jzuas).ant-picker-dropdown .ant-picker-year-panel .ant-picker-content{
    height: 1px;
}

:where(.css-dev-only-do-not-override-12upa3x).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, :where(.css-dev-only-do-not-override-12upa3x).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, :where(.css-dev-only-do-not-override-12upa3x).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #fff;
    background: #1a3c3f;
}

.form-control {
    border-color: #ededed;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    font-family: "Montserrat",Helvetica,Arial,serif,;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-picker:hover,
:where(.css-dev-only-do-not-override-12jzuas).ant-picker-focused {
    border-color: #1a3c3f;
    box-shadow: none;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-picker-dropdown .ant-picker-year-panel .ant-picker-cell-inner{
    padding:0px;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-picker-dropdown .ant-picker-cell .ant-picker-cell-inner {
    position: relative;
    z-index: 2;
    display: inline-block;
    min-width: 70px;
    height: 24px;
    line-height: 24px;
    border-radius: 4px;
    // transition: background 0.2s,border 0.2s;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-picker-dropdown .ant-picker-header-view button:hover{
    color: #1a3c3f;
}
// :where(.css-dev-only-do-not-override-12jzuas).ant-picker-dropdown .ant-picker-decade-panel,
// :where(.css-dev-only-do-not-override-12jzuas).ant-picker-dropdown .ant-picker-year-panel,
// :where(.css-dev-only-do-not-override-12jzuas).ant-picker-dropdown .ant-picker-quarter-panel,
// :where(.css-dev-only-do-not-override-12jzuas).ant-picker-dropdown .ant-picker-month-panel,
// :where(.css-dev-only-do-not-override-12jzuas).ant-picker-dropdown .ant-picker-week-panel,
// :where(.css-dev-only-do-not-override-12jzuas).ant-picker-dropdown .ant-picker-date-panel,
// :where(.css-dev-only-do-not-override-12jzuas).ant-picker-dropdown .ant-picker-time-panel {
//     height: 200px;
//     width: 180px;
// }
